import React from "react";
import Seo from "../components/seo";
import logo from "../images/evistamplogoNew.png";

const PrivacyPolicy = () => {
  return (
    <>
      <Seo title="Privacy policy" description="Privacy Policy for Evistamp" />
      <div>
        <div className="policy-heading">
          <img className="policy-logo" src={logo} />
          <h3>EVISTAMP Website Privacy Policy </h3>
          <p>
            Version 1.0 <br />
            Effective Date: April 1st, 2022 <br />
            Last Update: April 1st, 2022
            <br />
            <a href="https://evistamp.com">wwww.evistamp.com</a>{" "}
          </p>
        </div>
        <div className="policy-text">
          <p>
            This privacy notice transparently communicates to our website
            visitor the personal data processing operations that take place when
            browsing our website www.evistamp.com and any other subdomain
            *.evistamp.com. Please read this privacy policy carefully to
            understand how we handle your personal data.
          </p>
          <ul>
            <li>About Us</li>
            <p>
              Ricta Technologies S.R.L. (hereinafter “we, “us” or similar) is a
              Romanian limited company, whose registered office is in Lujerului
              Street, no 10, Bucharest, Romania, registered with the Bucharest
              Trade Registry under number J40/2684/2020, tax code 42312660. We
              collect and process several categories of personal data from you
              as a user of <a href="https://www.evistamp.com">https://www.evistamp.com</a> website (hereinafter the
              “Website”), which, in compliance with European Union data
              protection law, makes us a data controller for such data. We are
              committed to protecting your privacy. This privacy notice sets out
              the types of personal information we collect, why and how we
              collect and process that information, what we do with it, who we
              share it with and certain rights and options that you have in this
              respect.
            </p>
            <li>Personal data collected</li>
            <p className="policy-subtitle">
              2.1 What personal data we collect?
            </p>
            <p>
              We treat any information relating to an identified or identifiable
              natural person as "personal data". This includes name,
              identification number, location data as well as online identifiers
              (e.g. IP address, cookie identifiers). In order to provide our
              Services, we need to process your personal data. The categories of
              personal data we process depend on your use of the Services,
              including whether you opt to create an account or not. In all
              cases, we collect only the minimum personal data necessary to
              achieve our purposes. Also, we do not knowingly collect or
              otherwise process sensitive data (e.g. personal data concerning
              your health, religious or political views etc.). If we realize
              that we have collected such personal data, we will delete it as
              soon as practically possible. If you believe that any of such
              personal data was provided to us, please contact us at
              privacy@evistamp.com. We collect the content, communications, and
              other information you provide to us while using the Website and
              Services. For example, some pages on the Website allow you to send
              us emails, and such messages sent to us will contain your email
              address and any other additional information you wish to include.
              If you choose to create an account on the Website, you will be
              asked to provide some information through a registration form,
              including your email address and password. Once registered on the
              Website, you may also add the additional personal data to your
              account: your first and last name, phone number, billing address,
              job title, organization etc. You are responsible for providing us
              with accurate personal data and for keeping such data up to date.
              You may update your personal data by editing your user profile in
              your account.
            </p>
            <p className="policy-subtitle">2.2 How we collect personal data?</p>
            <p>
              <p>
                Personal Data may be collected or accessed in various ways,
                including:
              </p>
              <ul>
                <li>
                  directly from you (from your form input data on the Website);
                </li>
                <li>
                  noticed by us when you navigate on our website (cookies, IP,
                  website navigation, etc.);
                </li>
              </ul>
            </p>
            <p className="policy-subtitle">
              2.2.1 Data provided by you directly
            </p>
            <p>
              By accessing the website and providing personal data to us, you
              acknowledge you have read this privacy notice and, to the extent
              your consent is necessary and valid under applicable law, you
              consent to the processing of your personal data in accordance with
              this privacy notice.
            </p>
            <p>
              When you visit our website, we process the following information
              about you:
            </p>
            <p>
              <ul>
                <li>
                  If you wish to contact us: Our Website gives you the ability
                  to send us a message via the contact form. This includes
                  fields such as name, email address and message. The data that
                  you input in the contact form is processed based in our
                  legitimate interest to respond to your inquiry and/or to keep
                  a record of your request, feedback and the like.
                </li>
                <li>
                  When you create an account: Creating an account implies
                  providing data to the Website. These data are normally the
                  following: your email address, a username, your first and last
                  name and your country. In the profile section of your account,
                  you can also fill in your phone number and other personal
                  data. You will also be required to set up a password to ensure
                  security of your account and billing details. Moreover, in the
                  event of a purchase, you will have to provide all your
                  personal data to the merchant of record.
                </li>
                <li>
                  When you subscribe to our newsletter: We will process your
                  data when you chose to subscribe to our newsletter by
                  providing your e-mail address. The legal ground on which we
                  base such processing is your consent, expressed by
                  subscribing. We will make sure to provide you with a simple
                  way to unsubscribe in any moment, either through the
                  respective communication or by contacting us using the contact
                  details at below. When you unsubscribe, you will stop
                  receiving our newsletter. Your e-mail address will be shared
                  with the e-mail solution provider.
                </li>
                <li>
                  What happens if you don’t give us your data: You can visit the
                  website without filling in any personal data. However, where
                  you wish to create an account or subscribe to our newsletter,
                  you cannot do that without providing the minimum amount of
                  information we need in order to deliver the service you are
                  requesting, authenticate you and be able to contact you if
                  needed.
                </li>
              </ul>
            </p>
            <p className="policy-subtitle">
              2.2.2 Data collected through website
            </p>
            <p>
              <p>
                When you use the Website, we collect (directly or through 3rd
                parties) certain information automatically and stores it in log
                files, namely:
              </p>
              <ul>
                <li>
                  <b>Location information.</b> When you connect to our Website,
                  a third-party service takes care of the security of the site
                  and therefore some location data could be collected, in
                  particular on the country of origin of the connections;
                </li>
                <li>
                  <b>Log data.</b> We automatically collect log information when
                  you use the Website and you’re logged in as the registered
                  user. So, we collect a unique identifier of the host running
                  the app, to bind it with the license. This unique identifier
                  is anonymous but it does identify the host based on multiple
                  hardware information;
                </li>
                <li>
                  <b>Transaction information.</b> We may collect limited
                  information related to your transactions on the website,
                  including the date and time and the amounts charged;
                </li>
                <li>
                  <b>Cookies.</b> A cookie is a small file of letters and
                  numbers that we put on your computer if you agree. For
                  detailed information about the use of cookies, please refer to
                  our Cookie Policy.
                </li>
              </ul>
              <p>
                We use this information to help us design our site to better
                suit our users’ needs.
              </p>
              <p>
                This privacy notice does not apply to third-party services that
                you opt to access through our website. While accessing
                third-party services (example the payment gateway), please make
                sure that you carefully review the terms and conditions and
                privacy policies governing the use thereof.
              </p>
            </p>
            <li>Data processing details</li>
            <p>
              <p>
                We process the personal data collected from you for the purposes
                specified hereinafter or as stated at the point of collection
                (or as obvious from the context of collection). In particular,
                we process your personal data for any of the following purposes:
              </p>
              <ul>
                <li>
                  <b>To administer and manage the Website.</b> We process your
                  personal data to confirm and authenticate your identity,
                  prevent unauthorized access to the restricted areas of the
                  Website (e.g. access to the Subscription Plan limited to the
                  Registered Users only). We also process your personal data to
                  maintain the integrity of our Services by detecting and
                  responding to harmful and any other conduct that violates our
                  other policies.
                </li>
                <li>
                  <b>To communicate with you.</b> We process your personal data
                  to provide you with the information that you have requested,
                  or that we think may be relevant to a subject in which you
                  have demonstrated an interest as well as to reply to any other
                  requests received from you;
                </li>
                <li>
                  <b>To provide, personalize and improve our Services.</b> We
                  process your personal data to provide and/or enable our
                  subcontractors to provide the Services requested by you. We
                  can also process your personal data to customize the contents
                  and features of our Website, remember your settings and
                  improve our service offerings. Your personal data can be
                  additionally processed for internal administrative purposes
                  and for accounting;
                </li>
                <li>
                  <b>To manage our relationship with Services.</b> We may add
                  your personal data to our customer management systems and
                  provide you with regular updates related to our Services as
                  well as changes that we introduce to our Terms of Use,
                  Subscription Plan, Privacy Policy, Cookie Policy and any other
                  documents from time to time;
                </li>
                <li>
                  <b>
                    To understand how you interact with our Website and Services
                    and improve your experience.
                  </b>{" "}
                  We can process your personal data to understand how you use
                  the features and functions on our Website, which content you
                  access, and tailor our Website and Services to your needs and
                  interests;
                </li>
                <li>
                  <b>To send marketing communications.</b> To the extent
                  permitted by the applicable law, we may process your personal
                  data to send you the information about our Services which may
                  be of interest to you and similar marketing information. We
                  may also use the technologies that allow us to understand
                  whether you opened a marketing email or clicked on a link
                  contained therein.
                </li>
              </ul>
              <p>
                We will disclose your personal data only for the purposes and to
                those third parties, as described below. We will take
                appropriate steps to ensure that your personal data are
                processed, secured, and transferred according to applicable law.
              </p>
              <p>
                We may need to engage third parties and, under certain
                circumstances, provide your personal data thereto for the
                purposes specified in this Privacy Policy:
              </p>
            </p>
            <p className="policy-subtitle">3.1 Disclosure to third parties</p>
            <p>
              <p>
                We will share the strictly necessary parts of your personal
                data, on a need-to-know basis with the following categories of
                third parties:
              </p>
              <ul>
                <li>
                  <b>Service providers.</b> We may need to share your personal
                  data with the service providers (sub-contractors) that are
                  engaged to provide the Services on our behalf, including the
                  customer and service support, marketing, security and fraud
                  prevention. Such third parties are authorized to process your
                  personal only to the extent permitted by us, and they are
                  prohibited from using your personal data for other purposes;
                </li>
                <li>
                  <b>Payment services.</b> We may access limited financial data
                  related to your payments made according to the Subscription
                  Plan. Most financial data, including your payment card
                  details, is processed by one of the payment service providers,
                  namely FastSpring used to manage and process your payments.
                  Your financial data is provided directly to the payment
                  service providers, and the use and storage thereof is governed
                  by the privacy policies of such third parties. For more
                  details, please refer to one of the following privacy policies
                  depending on your payment method:
                  <ul>
                    <li>
                      FastSpring:{" "}
                      <a href="https://fastspring.com/privacy">
                        https://fastspring.com/privacy
                      </a>
                    </li>
                  </ul>
                  <p>
                    We do keep a copy of your transaction information for the
                    reason to bind a specific license to your identity and your
                    transactions.
                  </p>
                  <p>
                    We do use your email address as link between your
                    transaction (license/product/services purchases) and our
                    database containing licenses.
                  </p>
                </li>
                <li>
                  <b>Marketing services.</b> In order to provide the information
                  about our Services and any other information which may be of
                  interest to you, we may use MailChimp, a third-party service
                  that provides the tools to create, send, and manage emails.
                  For more information, please refer to Section 3 of MailChimp
                  (The Rocket Science Group LLC) Privacy Policy available at:
                 <a href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a>
                </li>
                <li>
                  <b>Customer relationship management services.</b> We may use
                  MailChimp, a user database management service provided by
                  MailChimp (The Rocket Science Group LLC). Therein, we may
                  process your personal data provided to us in accordance with
                  this Privacy Policy. For more information, please refer to
                  Section 3 of MailChimp (The Rocket Science Group LLC) Privacy
                  Policy available at:{" "}
                  <a href="https://mailchimp.com/legal/privacy/">
                    https://mailchimp.com/legal/privacy/
                  </a>
                </li>
                <li>
                  <b>
                    Under certain circumstances, we are legally obliged to share
                    your personal data.
                  </b>{" "}
                  For example, we may reveal your personal data to any law
                  enforcement agency, court, regulator, government authority or
                  other organization if we are required to do so to meet a legal
                  or regulatory obligation, or otherwise to protect our rights
                  or the rights of anyone else. In such cases, we will satisfy
                  ourselves that we have a lawful basis on which we share the
                  personal data with such authorities and other organizations.
                  We will disclose your personal information to third parties:
                  <ul>
                    <li> If you request or authorize so.</li>
                    <li>
                      to persons demonstrating legal authority to act on your
                      behalf.
                    </li>
                    <li>
                      where it is in our legitimate interests to do so to run,
                      grow and develop our business:
                    </li>
                    <li>
                      if we sell any business or assets related to the Website
                      you are subscribing to, we may disclose your personal
                      information to the prospective buyer of such business or
                      assets, in order to ensure that the activity continues as
                      a going concern.
                    </li>
                    <li>
                      if our company is totally or partially acquired by a third
                      party, in which case personal information held by us will
                      automatically be one of the transferred assets;
                    </li>
                    <li>
                      if we are under a duty to disclose or share your personal
                      information in order to comply with any legal obligation,
                      any lawful request from government officials and as may be
                      required to meet national security or law enforcement
                      requirements or prevent illegal activity;
                    </li>
                    <li>
                      to respond to any claims, to protect our rights or the
                      rights of a third party, to protect the safety of any
                      person or to prevent any illegal activity; or
                    </li>
                    <li>
                      to protect the rights, property or safety of our company,
                      our employees, customers, suppliers or other persons.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Some of these recipients (including our affiliates) may use your
                data in countries which are outside of the European Economic
                Area. Please see Section 4 below for more detail on this aspect.
              </p>
              <p>
                While processing your personal data for any of the purposes
                specified herein, we ensure that we have a lawful basis that
                allows us to proceed with personal data processing. In every
                case, the lawful basis will be one of the following:
              </p>
              <ul>
                <li>
                  <b>Our legitimate interests.</b> We rely on our legitimate
                  interests where it is necessary to ensure the effective and
                  lawful operation of our business (provided that our interests
                  are not overridden by your interests and or fundamental rights
                  and freedoms). For example, we rely on our legitimate
                  interests when we provide our Services, understand how our
                  Services and/or Website are used and which Services are of
                  interest to you;
                </li>
                <li>
                  <b>
                    Performance of a contact with you or taking steps, at your
                    request, prior to entering into the contract.
                  </b>{" "}
                  For example, where you opt to become a Registered User and/or
                  purchase the Subscription Plan offered on the Website, we may
                  need to process your personal data in order to provide you
                  with the requested Services;
                </li>
                <li>
                  <b>Consent.</b> Where required to obtain your consent, we will
                  rely on your freely given, specific, informed, and unambiguous
                  consent to the processing of your personal data. For example,
                  we can process your personal data for marketing purposes
                  subject to your consent. You can withdraw your consent at any
                  time and, in the case of marketing emails, you can withdraw
                  your consent by clicking the unsubscribe link added thereto;
                </li>
                <li>
                  <b>
                    Compliance with a legal obligation to which we are subject.
                  </b>{" "}
                  As per the legal requirements applicable to us, we may be
                  obliged to collect and further store certain personal data
                  about you. For example, we may be subject to the accounting
                  requirements that would require us to store the information
                  about our customers for a certain period of time.
                </li>
              </ul>
            </p>
            <p className="policy-subtitle">
              3.3 Restrictions on use of personal information by recipients
            </p>
            <p>
              Any third parties with whom we choose to share your personal
              information pursuant to the above are limited (by law and by
              contract) in their ability to use your personal information for
              the specific purposes identified by us. We will always ensure that
              any third parties with whom we choose to share your personal
              information are subject to privacy and security obligations
              consistent with this privacy notice and applicable laws. However,
              for the avoidance of doubt, this cannot be applicable where the
              disclosure is not our decision.
            </p>
            <p>
              Save as expressly detailed above, we will never share, sell or
              rent any of your personal information to any third party without
              notifying you and, if applicable, obtaining your consent.
            </p>
            <p className="policy-subtitle">
              3.3 How and how long do we store your personal data?
            </p>
            <p>
              In order to save to store customer data collected via the Website,
              we use the platform made available by FaunaDb, and we use a
              FaunaDB server instance hosted in Europe.
            </p>
            <p>
              Your customer information’s are stored inside FaunaDB platform.
              Such platform is managed by Fauna Inc. to offer our customer the
              maximum level of security for his data.
            </p>
            <p>
              For more information, please refer to:{" "}
              <a href="https://fauna.com/privacy">https://fauna.com/privacy</a>
            </p>
            <p>
              We will retain your personal data only for as long as we need it,
              given the purposes for which it was collected, or as required to
              do so by law. Should you opt to become our Registered User or
              otherwise use the Services provided by us, we retain personal data
              about you for as long as you have an active account on the Website
              or as otherwise necessary to provide the Services. In some cases,
              we retain personal data for longer, if doing so is necessary to
              comply with our legal obligations, resolve disputes or as
              otherwise permitted or required by applicable law, rule, or
              regulation. For example, when you purchase a Subscription Plan on
              our Website, we will need to keep your personal data for
              accounting purposes for the period required by the applicable law.
              We keep mailing list information until you unsubscribe from our
              mailing lists as described herein and/or in the email that you
              receive. If you choose to unsubscribe from a mailing list, we may
              keep certain limited information about you so that we may honor
              your request and do not send you any marketing emails in future.
            </p>
            <li>Transfers of information outside of the European Union</li>
            <p>
              The personal information may be processed by staff operating
              outside the EEA working for us, other members of our group or
              third-party data processors for the purposes mentioned above.
            </p>
            <p>
              While we act as a data controller in respect of your personal
              data, we may also transfer your personal data to and store it
              outside the country where you are located. This may be necessary
              to provide the Services set forth in our Terms of Use or for other
              purposes specified herein.
            </p>
            <p>
              <p>
                Such cross-border transfers may include the countries outside
                the European Economic Area ("EEA") and countries that do not
                have laws that would provide specific protection for personal
                data. Where we collect your personal data within the EEA,
                transfer outside the EEA will be only:
              </p>
              <ul>
                <li>
                  to a recipient located in a country which provides an adequate
                  level of protection for your personal data; and/or
                </li>
                <li>
                  under an agreement which satisfies the European Union
                  requirements for the transfer of personal data to data
                  processors or data controllers outside the EEA, such as
                  standard contractual clauses approved by the European
                  Commission.
                </li>
              </ul>
            </p>
            <p>
              Further details on the steps we take to protect your personal
              information in these cases is available from us on request by
              contacting us (see section 8) at any time.
            </p>
            <li>Customer rights</li>
            <p>
              As a data subject, you have specific legal rights relating to the
              personal data we collect from you. We will respect your individual
              rights and will deal with your concerns adequately.
              <ul>
                <li>
                  Right to withdraw consent: Where you have given consent for
                  the processing of your personal data, you may withdraw your
                  consent at any moment.
                </li>
                <li>
                  Right to rectification: You may obtain from us rectification
                  of personal data concerning you. We make reasonable efforts to
                  keep personal data in our possession or control which are used
                  on an ongoing basis, accurate, complete, current and relevant,
                  based on the most recent information available to us. In
                  certain situations (such as profile data in account module),
                  we provide a self-service mechanism so that where users have
                  the possibility to review and rectify their personal data.
                </li>
                <li>
                  Right to restriction: You may obtain from us restriction of
                  processing of your personal data, if:
                  <ul>
                    <li>
                      you contest the accuracy of your personal data, for the
                      period we need to verify the accuracy,
                    </li>
                    <li>
                      the processing is unlawful, but you object to the erasure
                      of the personal data, requesting instead the restriction
                      of its use,
                    </li>
                    <li>
                      we do no longer need your personal data, but you request
                      it for the establishment, exercise or defense of legal
                      rights, or
                    </li>
                    <li>
                      you object to the processing while we verify whether our
                      legitimate interests override yours.
                    </li>
                  </ul>
                </li>
                <li>
                  Right to access: You may ask us for information regarding
                  personal data that we hold about you, including information as
                  to which categories of data we have in our possession, what it
                  is being used for, where we collected it if obtained
                  indirectly, and to whom it is disclosed, if applicable. We
                  will provide you with a copy of your personal data upon
                  request.
                </li>
                <li>
                  Right to portability: You have the right to receive your
                  personal data that you have provided to us, and, where
                  technically feasible, request that we transmit your personal
                  data (that you have provided to us) to another organization.
                  <p>You have these rights if, on a cumulative basis:</p>
                  <ul>
                    <li>
                      we process your personal data by automated means, and the
                      transmission is technically feasible;
                    </li>
                    <li>
                      we base the processing of your personal data on your
                      consent, or our processing of your personal data is
                      necessary for the execution or performance of a contract
                      to which you are a party;
                    </li>
                    <li>your personal data is provided to us by you; and</li>
                    <li>
                      the transmission of your personal data does not adversely
                      affect the rights and the freedoms of other persons.
                    </li>
                  </ul>
                </li>
                <li>
                  Right to erasure: You have the right to request that we delete
                  the personal data we process about you. We must comply with
                  this request if we process your personal data, unless such
                  data is necessary:
                  <ul>
                    <li>
                      for exercising the right of freedom of expression and
                      information;
                    </li>
                    <li>
                      for compliance with a legal obligation that binds us;
                    </li>
                    <li>
                      {" "}
                      for archiving purposes in the public interest, scientific
                      or historical research purposes or statistical purposes;
                      or
                    </li>
                    <li>
                      for the establishment, exercise or defense of legal
                      rights.
                    </li>
                  </ul>
                </li>
                <li>
                  Right to object: You may object – at any time – to the
                  processing of your personal data due to your particular
                  situation, provided that the processing is not based on your
                  consent but on our legitimate interests or those of a third
                  party. In this event we shall no longer process your personal
                  data, unless (i) we can demonstrate compelling legitimate
                  grounds and an overriding interest for the processing or (ii)
                  if the purpose is the establishment, exercise or defense of
                  legal claims. If you object to the processing, please specify
                  whether you also wish the erasure of your personal data,
                  otherwise we will only restrict it.
                </li>
              </ul>
              <p>
                You may always object to the processing of your personal data
                for direct marketing that was based on our legitimate interest,
                regardless of any reason. If the marketing was based on your
                consent, you can withdraw consent.
              </p>
              <p>Other considerations:</p>
              <ul>
                <li>
                  {" "}
                  <b>Limited responsibility:</b> customer is solely responsible
                  for the authenticity of the data provided. Evistamp will not
                  perform any check in this regard. Also no responsibility is
                  taken towards the customer or third parties.
                </li>
                <li>
                  <b>Time period:</b> We will try to fulfill your request within
                  one month. Nevertheless, this period may be extended with up
                  to three months due to reasons relating to the specific legal
                  right or the complexity of your request. In all cases, if this
                  period is extended, we will inform you about the term of
                  extension and the reasons that led to it.
                </li>
                <li>
                  <b>Restriction of access:</b> In certain situations, we may
                  not be able to give you access to all or some of your personal
                  data due to statutory restrictions. If we deny or limit your
                  access, we will advise you of the reason for such measure.
                </li>
                <li>
                  <b>No identification:</b> In some cases, we may not be able to
                  look up your personal data due to the identifiers you provide
                  in your request. In such cases, where we cannot identify you
                  as a data subject, we are not able to comply with your request
                  to execute your legal rights as described in this section,
                  unless you provide additional information enabling your
                  identification. We will inform you and give you the
                  opportunity to provide such additional details.
                </li>
                <li>
                  <b>Exercise of your rights:</b> In order to exercise your
                  rights please contact us in writing or electronically at the
                  contact addresses provided for in Section 8 below.
                </li>
              </ul>
            </p>
            <li>How do we protect your personal data?</li>
            <p>
              We are committed to protecting personal information from loss,
              misuse, disclosure, alteration, unavailability, unauthorized
              access and destruction and takes all reasonable precautions to
              safeguard the confidentiality of personal information, including
              through use of appropriate organizational and technical measures.
              Organizational measures include physical access controls to our
              premises, staff training and locking physical files in filing
              cabinets. Technical measures include use of encryption, passwords
              for access to our systems and use of anti-virus software.
            </p>
            <p>
              In the course of provision of your personal data to us, your
              personal information may be transferred over the internet.
              Although we make every effort to protect the personal information
              which you provide to us, the transmission of information over the
              internet is not completely secure. As such, we cannot guarantee
              the security of your personal information transmitted to us over
              the internet and that any such transmission is at your own risk.
              While we endeavor to protect our systems and services, we cannot
              guarantee the security of the personal data transmitted to or by
              us and we do not guarantee or warrant that the measures taken by
              us will prevent unauthorized access to personal data that we
              process.
            </p>
            <li>Changes to our privacy notice</li>
            <p>
              We reserve the right, at our discretion, to modify our privacy
              practices and update and make changes to this privacy notice at
              any time. For this reason, we encourage you to refer to this
              privacy notice on an ongoing basis. This privacy notice is current
              as of the date which appears at the top of the document. We will
              treat your personal data in a manner consistent with the privacy
              notice under which they were collected, unless we have your
              consent to treat them differently.
            </p>
            <li>Contact information, requests, and complaints</li>
            <p>
              If you have any questions about this Privacy Policy or Fauna,
              please contact us here or send any correspondence to “Fauna Inc.,
              548 Market Street #87043, San Francisco, CA 94104, Attn: Legal.”
            </p>
            <p>
              We will investigate and use all reasonable efforts to resolve any
              request or complaint regarding the use or disclosure of your
              personal information.
            </p>
            <p>
              If you are not satisfied with our reply, you may also make a
              complaint to the relevant data protection supervisory authority
              (in Romanian: National Supervisory Authority for Processing of
              Personal Data or ANSPDCP). You can find further information about
              the process of lodging complaints with ANSPDCP at
              <a href="https://www.dataprotection.ro/?page=procedura_plangerilor">https://www.dataprotection.ro/?page=procedura_plangerilor</a>, and you
              can file a complaint using the form available at
              <a href="https://www.dataprotection.ro/?page=Plangeri_RGPD&lang=ro">https://www.dataprotection.ro/?page=Plangeri_RGPD&lang=ro</a> or by
              contacting ANSPDCP directly by email at anspdcp@dataprotection.ro
              or by post at 28-30 G-ral. Gheorghe Magheru Blvd., Sector 1,
              postal code 010336, Bucharest, Romania.
            </p>
            <p>In case the above contact is not reachable anymore please refer to: <a href="www.dataprotection.ro">www.dataprotection.ro</a>.</p>
          </ul>
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicy;
